<template>
  <div class="main_profile_wrapper pt-4 w-100">
    <div class="container-fluid">
      <el-tabs type="border-card" v-model="activeTab">
        <!-- Personal Info Form  -->
        <el-tab-pane label="Personal Info" name="personalInfo">
          <span slot="label">
            <span>{{ localization("Account Info") }}</span>
          </span>

          <div class="container-fluid p-0 m-0">
            <div class="row pb-3">
              <div class="col">
                <div class="pt-3">
                  <h5 class="m-0">{{ localization("Personal information") }}</h5>
                  <p class="text-muted">
                    {{ localization("Enter your personal information, all fields are required.") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-8 col-xl-6">
                <el-form
                  ref="personalInfoFormRef"
                  :model="user"
                  :rules="personalInfoRules"
                  label-position="top"
                  @submit.prevent="save"
                >
                  <!-- <el-checkbox
                      v-model="user.public"
                    >
                      {{ localization("Public Profile") }}
                    </el-checkbox> -->
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- First Name  -->
                        <el-form-item
                          :label="`${localization('First Name')} *`"
                          label-width="20%"
                          prop="first_name"
                        >
                          <el-input
                            :placeholder="`${localization('First Name')}`"
                            v-model="user.first_name"
                          />
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <!-- Last Name  -->
                        <el-form-item
                          :label="`${localization('Last Name')} *`"
                          label-width="20%"
                          prop="last_name"
                        >
                          <el-input
                            v-model="user.last_name"
                            :placeholder="`${localization('Last Name')}`"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <!-- Email  -->
                        <el-form-item
                          :label="`${localization('Email')} *`"
                          label-width="20%"
                          prop="email"
                        >
                          <el-input
                            :placeholder="`${localization('Email')}`"
                            v-model="user.email"
                          />
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <!-- Age  -->
                        <el-form-item
                          :label="`${localization('Age Range')} *`"
                          label-width="20%"
                          prop="age_group_id"
                        >
                          <el-select
                            v-model="user.age_group_id"
                            :placeholder="`${localization('Age')}`"
                            class="w-100"
                          >
                            <el-option
                              v-for="(item, index) in ageList"
                              :key="index"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <!-- Language  -->
                        <el-form-item
                          :label="`${localization('Language')}`"
                          label-width="20%"
                          prop="language"
                        >
                          <el-select
                            v-model="user.language"
                            :placeholder="`${localization('Language')}`"
                            class="w-100"
                          >
                            <el-option
                              v-for="item in $store.getters['language/languages']"
                              :value="item.id"
                              :label="item.title"
                              :key="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="pt-3">
                          <h5 class="m-0">{{ localization("Location information") }}</h5>
                          <p class="text-muted">
                            {{ localization("Enter your personal information, all fields are required.") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <!-- Country  -->
                        <el-form-item
                          :label="`${localization('Country')}`"
                          label-width="20%"
                          prop="country"
                        >
                          <el-select
                            v-model="user.country"
                            filterable
                            :placeholder="`${localization('Country')}`"
                            class="w-100"
                          >
                            <el-option
                              v-for="(item, index) in countries"
                              :key="index"
                              :label="item"
                              :value="item"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <!-- City  -->
                        <el-form-item
                          :label="`${localization('City')} *`"
                          label-width="20%"
                          prop="city"
                        >
                          <el-input
                            :placeholder="`${localization('City')}`"
                            v-model="user.city"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row pt-3">
                      <div class="col-12">
                        <button
                          class="lci_btn save mt-3"
                          @click="save"
                          type="button"
                        >
                          {{ localization("Change My Info") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="Google Class"
          name="googleClass"
          v-loading="classroomLoading"
        >
          <span slot="label"
            ><i class="el-icon-users"></i>
            {{ localization("Google Classroom") }}
          </span>

          <div class="container-fluid p-0 m-0">
            <div class="row pb-3">
              <div class="col">
                <div class="pt-3">
                  <h5 class="m-0">
                    {{ localization("Google Classroom") }}
                  </h5>
                  <p class="text-muted">
                    {{ localization("Enter your Google Classroom email.") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-8 col-xl-6">
                <el-form
                  ref="googleClassFormRef"
                  :model="syncForm"
                  :rules="googleClassRules"
                  label-position="top"
                >
                  <el-form-item
                    :label="`${localization('Email')}`"
                    label-width="20%"
                    prop="email"
                  >
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-12">
                          <el-input
                            :placeholder="`${localization('Email')}`"
                            v-model="syncForm.email"
                            :disabled="this.user.student_classroom_id !== null"
                          />
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                  <button
                    class="lci_btn save mt-3"
                    v-if="!this.user.student_classroom_id"
                    @click="syncClass()"
                  >
                    {{ localization("Sync Class") }}
                  </button>

                  <button
                    v-else
                    class="lci_btn save mt-3"
                    @click="unsyncClass()"
                  >
                    {{ localization("Unsync class") }}
                  </button>
                </el-form>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!--//? Institution Info Form  -->
        <el-tab-pane
          name="institutionInfo"
          label="Institution Info"
          v-loading="isInstitutionInfoLoading"
        >
          <span slot="label">
            {{ localization("Institution Info") }}
          </span>

          <!-- <div class="custom-alert p-2 my-3">
            <span class="mr-2">
              <img src="@/assets/images/icons/alert-circle.png" alt="">
            </span>
            <span>Note: If you leave the Institution you will lose everything on your account related to this Institution.</span>
          </div> -->

          <div class="container-fluid p-0 m-0">
            <div class="row pb-3">
              <div class="col">
                <div class="pt-3">
                  <h5 class="m-0">
                    {{ localization("Institution Info") }}
                  </h5>
                  <p class="text-muted">
                    {{ localization("Your Institution Info below.") }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 col-lg-8 col-xl-6"
                v-if="user.organization_id != null"
              >
                <el-form
                  ref="personalInfoFormRef"
                  :model="user"
                  :rules="personalInfoRules"
                  label-position="top"
                  @submit.prevent="save"
                >
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12">
                        <el-form-item
                          :label="localization('Institution Name')"
                          label-width="20%"
                          prop="city"
                        >
                          <el-input disabled v-model="user.organization_name" />
                        </el-form-item>
                      </div>
                      <div class="col-12">
                        <el-form-item
                          :label="localization('Institution Email')"
                          label-width="20%"
                          prop="city"
                        >
                          <el-input
                            disabled
                            v-model="user.organization_email"
                          />
                        </el-form-item>
                      </div>
                      <div class="col-12">
                        <el-form-item
                          :label="localization('Institution Contact')"
                          label-width="20%"
                          prop="city"
                        >
                          <el-input
                            disabled
                            v-model="user.organization_contact_name"
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="col-12" v-else>
                {{ localization("Institution not found") }}
              </div>
              <div class="col-12 pt-4">
                <button
                  v-if="!user.organization_id"
                  class="lci_btn save mt-3"
                  @click="show_institution_promo_dialog = true"
                >
                  {{ localization("Join Institution") }}
                </button>

                <button
                  v-if="user.organization_id"
                  @click="leaveOrganization"
                  class="leave_btn"
                >
                  <img src="@/assets/images/icons/log-out-01.png" alt="" />
                  {{ localization("Leave Institution") }}
                </button>
              </div>
            </div>
          </div>

          <!-- //? Join new Institution Form  -->
          <el-dialog
            :title="`${localization('Enter Institution Promo Code')}`"
            :visible.sync="show_institution_promo_dialog"
            top="1%"
            width="60%"
            custom-class="task-modal global_modal rounded-xl"
            class="dialog-center"
          >
            <div class="custom_modal_header">
              <div class="images">
                <img src="@/assets/new-theme/join.png" alt="file" />
                <img
                  @click="show_institution_promo_dialog = false"
                  class="close_img"
                  src="@/assets/new-theme/x-close-modal.png"
                  alt="file"
                />
              </div>
              <h3 class="title font-weight-bold mt-3">
                {{ localization("Join an institution") }}
              </h3>
              <p class="description">
                {{ localization("Please enter the promo code of the institution to join.") }}
              </p>
            </div>
            <el-form
              :model="promoForm"
              v-loading="isInstitutionInfoLoading"
              :rules="promoRules"
              ref="promoRef"
              @submit.prevent="submitPromoCode"
            >
              <el-form-item
                :label="`${localization('Promo Code')}`"
                prop="new_institution_promo"
              >
                <el-input
                  class="mt-2"
                  v-model="promoForm.new_institution_promo.promo_code"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="lci_modal_buttons mt-5">
              <button
                class="lci_btn_modal_cancel "
                @click="show_institution_promo_dialog = false"
              >
                Cancel
              </button>
              <button
                @click="submitPromoCode"
                class="lci_btn save lci_btn_modal_cancel"
              >
                Join
              </button>
            </div>
          </el-dialog>
        </el-tab-pane>

        <!-- //? Reset Password Form  -->
        <el-tab-pane
          name="resetPassword"
          label="Reset Password"
          v-loading="isResetPasswordLoading"
        >
          <span slot="label">
            {{ localization("Change Password") }}
          </span>
          <div class="container-fluid p-0 m-0">
            <div class="row pb-3">
              <div class="col">
                <div class="pt-3">
                  <h5 class="m-0">
                    {{ localization("Change Password") }}
                  </h5>
                  <p class="text-muted">
                    {{ localization("Enter your new password.") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-8">
                <el-form
                  v-model="resetPassword"
                  :model="resetPassword"
                  ref="resetPassRef"
                  :rules="resetPassRules"
                >
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          :label="`${localization('password')}`"
                          prop="password"
                        >
                          <el-input
                            :type="passwordType"
                            v-model="resetPassword.password"
                            class="mt-2"
                            :placeholder="
                              `${localization('Please enter Password')}`
                            "
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          :label="`${localization('Confirm Password')}`"
                          prop="confirmPassword"
                          class="position-relative"
                        >
                          <el-input
                            :type="confirmPasswordType"
                            class="mt-2"
                            v-model="resetPassword.confirmPassword"
                            :placeholder="
                              `${localization('Please enter Password')}`
                            "
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button
                          type="button"
                          class="lci_btn save mt-3"
                          @click="submit"
                        >
                          {{ localization("Submit") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!--//? Billing Info Form  -->
        <!-- Only show this tab if user is a stundent or an inndividual -->
        <el-tab-pane
          name="billingInfo"
          label="Billing Info"
          v-if="this.showBillingInfo"
        >
          <span slot="label"> {{ localization("Billing Info") }}</span>
          <div class="p-1">
            {{ localization(this.user.type) }}
          </div>
          <el-alert show-icon>
            {{ localization("By adding a credit card, you are subscribing to PersonalLearningCoach.com and will be billed annually.") }}
          </el-alert>

          <div class="container-fluid p-0 m-0">
            <div class="row">
              <div class="col-12 col-lg-8 col-xl-6">
                <el-form
                  ref="processPaymentFormRef"
                  :model="credit"
                  :rules="paymentRules"
                  label-position="top"
                >
                  <div class="container-fluid p-0">
                    <div class="row pt-5">
                      <div class="col-md-6">
                        <el-form-item
                          :label="`${localization('Card Name')}`"
                          label-width="30%"
                          prop="card_name"
                        >
                          <el-input
                            v-model="credit.card_name"
                            :placeholder="`${localization('Card Name')}`"
                          />
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          :label="`${localization('Card Number')}`"
                          label-width="30%"
                          prop="card_number"
                        >
                          <el-input
                            v-model="credit.card_number"
                            :placeholder="`${localization('Card Number')}`"
                            maxlength="16"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item
                          :label="`${localization('Expiration Date')}`"
                          label-width="30%"
                          prop="exp_time"
                        >
                          <el-date-picker
                            v-model="credit.exp_time"
                            type="month"
                            :placeholder="`${localization('Expiration Date')}`"
                            format="MM/yy"
                            value-format="yyyy-MM"
                            :picker-options="datePickerOptions"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item
                          :label="`${localization('CVC')}`"
                          label-width="30%"
                          prop="cvc_code"
                        >
                          <el-input
                            v-model="credit.cvc_code"
                            :placeholder="`${localization('CVC')}`"
                            maxlength="3"
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <el-form-item
                        :label="`${localization('Plans')}`"
                        label-width="30%"
                        prop="cvc_code"
                      >
                        <el-select
                          v-model="credit.plan"
                          :placeholder="`${localization('Select a plan')}`"
                        >
                          <el-option
                            label="Basic Plan"
                            value="basic"
                          ></el-option>
                          <el-option
                            label="Professional plan"
                            value="professional"
                          ></el-option>
                          <el-option
                            label="Family plan"
                            value="family"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <el-checkbox
                        v-model="credit.auto_renew"
                        :label="`${localization('Auto Renew')}`"
                        color="green"
                        :checked="autoRenewCheck"
                      ></el-checkbox>
                    </div>
                  </div>
                  <div class="row pt-5">
                    <div class="col-12">
                      <button
                        type="button"
                        class="lci_btn save"
                        @click="processPayment"
                      >
                        {{ localization("Process Payment") }}
                      </button>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "PersonalizeProfile",
  data() {
    return {
      menu: false,
      minDate: new Date(Date.now()).toISOString().substr(0, 10),
      isInstitutionInfoLoading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      personalInfoRules: {
        first_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      personalStatementsRules: {
        sequence_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        precision_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        technical_reasoning_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        confluence_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      googleClassRules: {
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      syncForm: { email: "" },
      dataSyncedWithClass: false,
      classroomLoading: false,
      paymentRules: {
        card_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        card_number: [
          {
            min: 16,
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        exp_time: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        cvc_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
            min: 3
          }
        ]
      },
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password_confirmation: null,
        password: null,
        age_group_id: null,
        language: null,
        gender: null,
        type: null,
        state: null,
        country: null,
        city: null,
        student_classroom_id: null,
        zip_code: null,
        auto_renew: false,
        public: null
        // zip: null,
      },
      credit: {
        card_name: null,
        card_number: null,
        auto_renew: false,
        exp_time: null,
        cvc_code: null
      },
      email: {
        confirmemail: null
      },
      ageList: [
        { value: 1, label: "6-12 years old" },
        { value: 2, label: "13 -21 years old" },
        { value: 3, label: "22 or older" },
        { value: 4, label: "ABE" }
      ],

      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia ",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory ",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands ",
        "Central African Republic ",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands ",
        "Colombia",
        "Comoros ",
        "Congo ",
        "Congo ",
        "Cook Islands ",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic ",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands  [Malvinas]",
        "Faroe Islands ",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories ",
        "Gabon",
        "Gambia ",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands ",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands ",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger ",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands ",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands ",
        "United States of America ",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ],
      show_institution_promo_dialog: false,
      promoForm: {
        new_institution_promo: {
          promo_code: null
        }
      },
      promoRules: {
        new_institution_promo: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      joinFullORgError: "",
      isResetPasswordLoading: false,
      passwordType: "password",
      confirmPasswordType: "password",
      resetPassword: {},
      resetPassRules: {
        password: [
          {
            required: true,
            message: "Please input password",
            trigger: "change"
          },
          {
            min: 8,
            message: "Password must be 8 characters or more",
            trigger: "change"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please reenter password",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.resetPassword.password) {
                callback(new Error("Passwords do not match"));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ]
      },
      // If user clicked Sync data from profile page: open edit profile with google classrooms tab active
      // else open personal Info tab
      activeTab:
        this.$route.path == "/sync-data" ? "googleClass" : "personalInfo"

      //  this.what_active == false ? "Statements"
    };
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    // auto auto_renew auto checked for US users
    autoRenewCheck() {
      return this.user.country === "United States";
    },
    showBillingInfo() {
      return (
        this.user.type != "Institution" &&
        this.user.type != "institution" &&
        !this.$route.params.id
      );
    },
    what_active() {
      return this.$store.getters["user/what_active"];
    }
  },
  mounted() {
    this.$store.dispatch("user/user", this.id).then(_ => {
      this.user = this.$store.state.user.alluserData;
      this.org = this.$store.getters["user/orgs"];
      this.syncForm.email = this.user.student_classroom_id;
      if(this.user.auto_renew){
        this.credit.auto_renew = this.user.auto_renew == 1 ? true : false;
      }
    });

    // get languages available languages for user language set
    this.$store.dispatch("language/getLanguages");
    // set dataSyncedWithClass variable if users data is synced with A classroom
    this.dataSyncedWithClass = this.user.student_classroom_id ? true : false;
  },
  methods: {
    handleCredentialResponse(event) {},
    //// start google auth
    handleClickLogin() {
      const googleUser = this.$gAuth.signIn();
      this.$gAuth
        .getAuthCode()
        .then(authCode => {
          //on success
        })
        .catch(error => {
          //on fail do something
        });
    },
    async handleClickGetAuth() {
      try {
        // window.gapi.auth2.init({

        const authCode = await this.$gAuth.getAuthCode();
        // const response = await this.$http.post('http://your-backend-server.com/auth/google', { code: authCode, redirect_uri: 'postmessage' })
      } catch (error) {
        // On fail do something
      }
    },
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
      } catch (error) {
        // On fail do something
        console.error(error);
        return null;
      }
    },
    Refresh(query) {
      this.$store.dispatch("user/users", { query: query });
      this.$store.dispatch("topics/GetTopics", { query: null });
    },

    save() {
      this.$refs["personalInfoFormRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/save", { id: `/${this.id}`, query: this.user })
            .then(_ => {
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success",
                showCancelButton: false,
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              // this.$store.dispatch("user/user", this.id);
              this.$router.go(-1);
            });
        } else {
          return false;
        }
      });
    },

    saveStatements() {
      this.$refs["personalStatementsRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/save", { id: `/${this.id}`, query: this.user })
            .then(response => {
              console.log("Response:", response);
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              // setTimeout(() => {
              //   this.$router.go(-1);
              // }, 3000);
            })
            .catch(error => {
              console.error("Error:", error);
            });
        } else {
          return false;
        }
      });
    },

    // save() {
    //   this.$refs["personalInfoFormRef"].validate(valid => {
    //     if (valid) {
    //       console.log("Sending payload:", { id: this.id, query: this.user });
    //       this.$store
    //         .dispatch("user/save", { id: this.id, query: this.user })
    //         .then(response => {
    //           console.log("Response:", response);
    //           if (!this.$route.params.id) {
    //             Auth(JSON.stringify(this.user));
    //           }
    //           // setTimeout(() => {
    //           //   this.$router.go(-1);
    //           // }, 3000);
    //         })
    //         .catch(error => {
    //           console.error("Error:", error);
    //         });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // saveStatements() {
    //   this.$refs["personalStatementsRef"].validate(valid => {
    //     if (valid) {
    //       this.$store
    //         .dispatch("user/save", { id: `/${this.id}`, query: this.user })
    //         .then(_ => {
    //           if (!this.$route.params.id) {
    //             Auth(JSON.stringify(this.user));
    //           }
    //           this.$router.go(-1);
    //         });
    //     } else {
    //       return false;
    //     }
    //   });
    // },

    leaveOrganization() {
      // set laoding to true to shpw loading while making request
      this.isInstitutionInfoLoading = true;

      this.$store
        .dispatch("user/leaveOrganization", { id: this.id })
        .then(_ => {
          this.$store.dispatch("user/user", this.id).then(_ => {
            // refresh after leaving institution
            this.user = this.$store.state.user.alluserData;
            this.org = this.$store.getters["user/orgs"];
            // remove loading
            this.isInstitutionInfoLoading = false;
          });
        });
    },

    submitPromoCode() {
      this.$refs["promoRef"].validate(valid => {
        if (valid) {
          this.isInstitutionInfoLoading = true;
          this.$store
            .dispatch("user/joinOrganization", {
              promoCode: this.promoForm.new_institution_promo,
              id: this.id
            })
            .then(res => {
              //  If Organiations is full, server will retur an error messgae with the response
              // this.joinFullORgError = `${this.localization(res.error_message)}`;
              this.joinFullORgError = this.$store.getters[
                "user/join_full_org_error"
              ];
              // if organization is full
              if (this.joinFullORgError.length > 0) {
                Swal.fire({
                  text: this.joinFullORgError,
                  text: `${this.localization(res.error_message)}`,
                  icon: "error",
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000
                }).then(() => {
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              } else {
                // if organization is not full
                // update data in panels

                this.$store.dispatch("user/user", this.user.id).then(() => {
                  // refresh after Joining institution
                  this.user = this.$store.state.user.alluserData;
                  this.org = this.$store.getters["user/orgs"];
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // update table data
                  // this.Refresh({});
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    processPayment() {
      this.$refs["processPaymentFormRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/processPayment", {
              id: `${this.id}`,
              query: this.credit
            })
            .then(res => {
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success",
                showCancelButton: false,
                buttonsStyling: false,
              });
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              this.$router.go(-1);
            });
        } else {
          return false;
        }
      });
    },
    // reste password methods
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleShowConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType == "password" ? "text" : "password";
    },
    // reset password
    submit() {
      this.$refs["resetPassRef"].validate(valid => {
        if (valid) {
          this.isResetPasswordLoading = true;
          //  If passwords does not match
          if (
            this.resetPassword.password !== this.resetPassword.confirmPassword
          ) {
            Swal.fire({
              icon: "error",
              text: `${this.localization("Password does not match")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              confirmButtonClass: "btn btn-info ",
              showConfirmButton: true,
              showCloseButton: true,
              buttonsStyling: false
            }).then(data => {
              if (data.isConfirmed) {
                this.$store.dispatch("user/user", this.id);
                this.isResetPasswordLoading = false;
              }
            });
          } else {
            // call reset password on store
            this.$store
              .dispatch("user/changePasswordByAdmin", {
                id: this.user.id,
                newPasswordData: {
                  password: this.resetPassword.password,
                  confirm_password: this.resetPassword.confirmPassword
                }
              })
              .then(() => {
                Swal.fire({
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  confirmButtonClass: "btn btn-success",
                  showConfirmButton: false,
                  showCloseButton: true
                });
                this.isResetPasswordLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },

    // join class room
    syncClass() {
      this.classroomLoading = true;
      this.$refs["googleClassFormRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/syncClassRoom", {
              id: `${this.id}`,
              email: { email: `${this.syncForm.email}` }
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                showConfirmButton: false,
                showCloseButton: false,
                timer: 2000
              }).then(() => {
                this.$router.push("/profile");
                this.classroomLoading = false;
              });
            });
        } else {
          this.classroomLoading = false;
          return false;
        }
      });
    },
    unsyncClass() {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(data => {
        if (data.isConfirmed) {
          this.classroomLoading = true;
          this.$store
            .dispatch("user/unsyncClassRoom", {
              id: this.id
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false,
                showConfirmButton: true,
                confirmButtonClass: "btn btn-success ",
                showCloseButton: false,
                timer: 2000
              }).then(() => {
                this.$router.push("/profile");
                this.classroomLoading = false;
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-radius: 10px !important;
}
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card,
.el-tabs__content {
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll {
  padding: 10px !important;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  border-top-color: #dcdfe6 !important;
  border-bottom-color: #dcdfe6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members {
  .el-table .cell {
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #344054;
}

::v-deep .el-tabs--border-card {
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
  border-bottom: unset !important;
}

::v-deep .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: unset !important;
}

::v-deep .el-form-item__label {
  line-height: 0 !important;
}

::v-deep .el-input__inner {
  border-radius: 8px !important;
}
// ::v-deep .el-input__inner::placeholder{
//   color: #006eff !important;
// }

::v-deep .select-default.el-select .el-input:hover input {
  background-color: #888888 !important;
}

.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 10px 15px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}

.custom-alert {
  border: 1px solid #fda29b;
  color: #d92d20;
  border-radius: 8px;
  background-color: #fffbfa;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #787b83;
  cursor: not-allowed;
}

.leave_btn {
  color: #d92d20;
  background: transparent;
  border: unset;
  font-weight: 600;
}
::v-deep .el-select .el-input:hover input {
  color: #888888 !important;
}
</style>
